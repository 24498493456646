import Vue from 'vue';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome';

import {
    faAngleDown,
    faAngleLeft,
    faBars,
    faCar,
    faClone,
    faDollarSign,
    faEdit,
    faExchangeAlt,
    faEye,
    faHotel,
    faList,
    faLock,
    faMapMarkedAlt,
    faPlus,
    faQuestionCircle,
    faSave,
    faSearch,
    faSync,
    faSignOutAlt,
    faStoreAlt,
    faToggleOff,
    faToggleOn,
    faTrashAlt,
    faUser,
    faUsers,
    faUserCircle,
    faBan,
    faPrint,
    faAngleRight,
    faAngleDoubleRight,
    faAngleDoubleLeft,
    faCheck,
    faClock,
    faCaretRight,
    faCaretLeft,
    faExternalLinkSquareAlt,
    faExclamationTriangle
} from '@fortawesome/free-solid-svg-icons';

library.add(faAngleDown, faAngleLeft, faBars, faCar, faClone, faDollarSign, faEdit, faExchangeAlt, faEye, faHotel, faList, faLock, faMapMarkedAlt,
    faPlus, faQuestionCircle, faSave, faSearch, faSync, faSignOutAlt, faStoreAlt, faToggleOff, faCaretRight, faCaretLeft,
    faToggleOn, faTrashAlt, faUser, faUsers, faUserCircle, faBan, faPrint, faAngleRight, faAngleDoubleRight, faAngleDoubleLeft,
    faCheck, faClock, faExclamationTriangle);

Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('font-awesome-layers', FontAwesomeLayers);
Vue.component('font-awesome-layers-text', FontAwesomeLayersText);