const baseUrl = process.env.VUE_APP_BASE_URL;

const api = '/api';
const logOut = '/logout';
const oauthToken = '/oauth/v2/token';
const userValidate = '/user-validate';

const branch = '/branch';
const businessEntity = '/business-entity';
const hotel = '/hotel';
const service = '/service';
const user = '/user';
const vehicle = '/vehicle';
const passengers = '/passengers';
const businessOrder = '/business-order';
const businessOrderItem = '/business-order-item';
const serviceOrder = '/service-order';
const accountRp = '/account-rp';
const payment = '/payment';
const paymentItem = '/payment-item';
const statusOrder = '/service-order-status';
const statusLock = '/service-order-lock';
const servicePage = '/service-order-page';
const page = '/page';
const transactionPage = '/transaction-page';
const expenseData = '/service-order-expense-data';
const expense = '/expense';
const report = '/report';
const unassigned = '/service-order-unassigned';

const changePassword = '/change-password';
const enable = '/enable';
const list = '/list';
const cancel = '/cancel';
const remove = '/remove';
const select = '/select';
const operators = '/operators';
const dataToAssign = '/data-to-assign';
const data = '/data';
const type = '/type';
const balance = '/balance';
const availability = '/availability';
const token = '/validate-token';
const verify = '/verify';

const scheduleService = '/service-schedule';
const serviceOrderItem = '/service-order-item';
const scheduleServiceOrder = '/service-order-schedule';
const dataToServiceItem = '/data-to-service-item';

module.exports = {
    userValidateUrl: () => `${baseUrl}${userValidate}`,
    oauthTokenUrl: () => `${baseUrl}${oauthToken}`,
    logOutUrl: () => `${baseUrl}${api}${logOut}`,
    userUrl: () => `${baseUrl}${api}${user}`,
    userListUrl: () => `${baseUrl}${api}${user}${list}`,
    userTokenUrl: () => `${baseUrl}${api}${user}${token}`,
    userEnableUrl: () => `${baseUrl}${api}${user}${enable}`,
    userChangePasswordUrl: () => `${baseUrl}${api}${user}${changePassword}`,
    userSelectUrl: () => `${baseUrl}${api}${user}${select}`,
    userOperatorsUrl: () => `${baseUrl}${api}${user}${operators}`,
    vehicleUrl: id => `${baseUrl}${api}${vehicle}${id ? '/' + id : ''}`,
    vehicleListUrl: () => `${baseUrl}${api}${vehicle}${list}`,
    vehicleEnableUrl: () => `${baseUrl}${api}${vehicle}${enable}`,
    vehicleAvailabilityUrl: () => `${baseUrl}${api}${vehicle}${availability}`,
    hotelUrl: id => `${baseUrl}${api}${hotel}${id ? '/' + id : ''}`,
    hotelListUrl: () => `${baseUrl}${api}${hotel}${list}`,
    hotelEnableUrl: () => `${baseUrl}${api}${hotel}${enable}`,
    hotelSelectUrl: () => `${baseUrl}${api}${hotel}${select}`,
    businessEntityUrl: id => `${baseUrl}${api}${businessEntity}${id ? '/' + id : ''}`,
    businessEntityListUrl: () => `${baseUrl}${api}${businessEntity}${list}`,
    businessEntitySelectUrl: () => `${baseUrl}${api}${businessEntity}${select}`,
    businessEntityTypeUrl: () => `${baseUrl}${api}${businessEntity}${type}`,
    businessEntityEnableUrl: () => `${baseUrl}${api}${businessEntity}${enable}`,
    serviceUrl: id => `${baseUrl}${api}${service}${id ? '/' + id : ''}`,
    serviceListUrl: () => `${baseUrl}${api}${service}${list}`,
    serviceSelectUrl: () => `${baseUrl}${api}${service}${select}`,
    serviceEnableUrl: () => `${baseUrl}${api}${service}${enable}`,
    serviceScheduleUrl: id => `${baseUrl}${api}${service}${scheduleService}${id ? '/' + id : ''}`,
    branchUrl: id => `${baseUrl}${api}${branch}${id ? '/' + id : ''}`,
    branchListUrl: () => `${baseUrl}${api}${branch}${list}`,
    branchSelectUrl: () => `${baseUrl}${api}${branch}${select}`,
    branchEnableUrl: () => `${baseUrl}${api}${branch}${enable}`,
    businessOrderUrl: id => `${baseUrl}${api}${businessOrder}/${id ? id : ''}`,
    businessOrderListUrl: () => `${baseUrl}${api}${businessOrder}${list}`,
    businessOrderCancelUrl: () => `${baseUrl}${api}${businessOrder}${cancel}`,
    businessOrderPageUrl: () => `${baseUrl}${api}${businessOrder}${page}`,
    businessOrderItemDataToAssignUrl: () => `${baseUrl}${api}${businessOrderItem}${dataToAssign}`,
    businessOrderItemPassengersUrl: id => `${baseUrl}${api}${businessOrderItem}${passengers}${id ? '/' + id : '/'}`,
    businessOrderItemCancelUrl: id => `${baseUrl}${api}${businessOrderItem}${cancel}${id ? '/' + id : '/'}`,
    serviceOrderUrl: id => `${baseUrl}${api}${serviceOrder}${id ? '/' + id : '/'}`,
    serviceOrderListUrl: () => `${baseUrl}${api}${serviceOrder}${list}`,
    serviceOrderStatusUrl: id => `${baseUrl}${api}${serviceOrder}${statusOrder}${id ? '/' + id : '/'}`,
    serviceOrderLockUrl: id => `${baseUrl}${api}${serviceOrder}${statusLock}${id ? '/' + id : '/'}`,
    serviceOrderScheduleUrl: id => `${baseUrl}${api}${serviceOrder}${scheduleServiceOrder}${id ? '/' + id : '/'}`,
    servicePageUrl: id => `${baseUrl}${api}${serviceOrder}${servicePage}${id ? '/' + id : '/'}`,
    serviceOrderItemDataToServiceUrl: id => `${baseUrl}${api}${serviceOrderItem}${dataToServiceItem}/${id ? id : ''}`,
    serviceOrderExpenseDataUrl: (id, serviceId) =>`${baseUrl}${api}${serviceOrder}${expenseData}${serviceId ? '/' + serviceId : '/'}${id ? '/' + id : '/'}`,
    serviceOrderExpenseUrl: id =>`${baseUrl}${api}${serviceOrder}${expense}${id ? '/' + id : '/'}`,
    serviceOrdersUnassignedUrl: () =>`${baseUrl}${api}${serviceOrder}${unassigned}`,
    serviceOrderRemoveUrl: () => `${baseUrl}${api}${serviceOrder}${remove}`,
    serviceOrderCancelUrl: () => `${baseUrl}${api}${serviceOrder}${cancel}`,
    accountRpUrl: id => `${baseUrl}${api}${accountRp}/${id ? id : ''}`,
    accountRpListUrl: () => `${baseUrl}${api}${accountRp}${list}`,
    accountDataUrl: () => `${baseUrl}${api}${accountRp}${data}`,
    accountRpPageUrl: () => `${baseUrl}${api}${accountRp}${page}`,
    paymentUrl: id =>`${baseUrl}${api}${payment}${id ? '/' + id : '/'}`,
    paymentListUrl: () => `${baseUrl}${api}${payment}${list}`,
    paymentBalanceURL: () => `${baseUrl}${api}${payment}${balance}`,
    paymentExpenseUrl: id =>`${baseUrl}${api}${payment}${expense}${id ? '/' + id : '/'}`,
    paymentCancelUrl: () => `${baseUrl}${api}${payment}${cancel}`,
    paymentVerifyUrl: () => `${baseUrl}${api}${payment}${verify}`,
    paymentPageUrl: () => `${baseUrl}${api}${payment}${page}`,
    paymentItemCancelUrl: () => `${baseUrl}${api}${paymentItem}${cancel}`,
    paymentItemVerifyUrl: () => `${baseUrl}${api}${paymentItem}${verify}`,
    transactionPageUrl: () => `${baseUrl}${api}${paymentItem}${transactionPage}`,
    expenseListUrl: () => `${baseUrl}${api}${expense}${list}`,
    expenseUrl: id => `${baseUrl}${api}${expense}${id ? '/' + id : ''}`,
    reportUrl: () => `${baseUrl}${api}${report}`
};